import React, { useEffect, useState } from 'react';
import './css/style.css'; // Assuming you have a CSS file for styling
import logo from "./images/the.png";
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import axios from 'axios';
import { message } from "antd"
import Otp from './otp';
import PaymentPage from '../VisitorRegistration/PaymentPage';
import PaymentDetail from '../VisitorRegistration/PaymentDetail';
import PrintPage from '../VisitorRegistration/PrintPage';
const Index = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({ name: "", contact: "" });
  const [errors, setErrors] = useState({ name: "", contact: "" });
  const [encrypted_data, setEncrypted_data] = useState("");
  const [shareBarcode, setShareBarcode] = useState(null)
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Prevent non-numeric input in contact field
    if (name === "contact") {
      if (!/^\d*$/.test(value)) return; // Allow only digits in the contact field
    }

    // Prevent non-alphabetic input in name field
    if (name === "name") {
      if (!/^[A-Za-z\s]*$/.test(value)) return; // Allow only alphabets and spaces in the name field
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: "", contact: "" };

    // Check if the name contains only alphabetic characters
    if (!formData.name || !/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = "Name should contain only alphabetic characters.";
      isValid = false;
    }

    // Check if the contact is numeric and exactly 10 digits
    if (!formData.contact || !/^\d{10}$/.test(formData.contact)) {
      newErrors.contact = "Mobile number should contain exactly 10 digits.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {

      // Submit the form or perform other actions
      const sendData = new FormData();
      sendData.append("contact", formData.contact);
      sendData.append("name", formData.name);

      axios
        .post(`https://jjs.samaksh.in/api/visitor/v2/generate-otp`, sendData)
        .then((resData) => {
          console.log("hello student", resData.data);
          console.log("Form submitted!");
          setStep(step + 1);
          setShareBarcode(resData.data.barcode)
          // localStorage.setItem("barcode", resData.data.barcode);
          message.success("Otp send");
        });
    } else {
      message.error("fill valid data");
    }

  };

  useEffect(() => {
    if (localStorage.getItem("barcode")) {

      const formData = new FormData();
      formData.append("barcode", localStorage.getItem("barcode"));

      axios
        .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
        .then((resData) => {

          console.log("hello i am here vistior", resData.data.visitor[0].step)
          setStep(resData?.data?.visitor[0]?.step || 1)

        });
    }
  }, []);
  useEffect(() => {
    console.log("step", step)
  }, [step])

  return (
    <div>
      {step === 1 && (
        <div className="wrapper">
          <div className="image-holder">
            <img src={logo} alt="Show Logo" />
          </div>
          <div className="form-inner">
            <div className="form-header">
              <h3>Register Here for the Show</h3>
            </div>
            <div className="form-header">
              <ul>
                <li className={step >= 1 ? "filled" : ""}>1</li>
                <li className={step >= 2 ? "filled" : ""}>2</li>
                <li className={step >= 3 ? "filled" : ""}>3</li>
                <li className={step >= 4 ? "filled" : ""}>4</li>
                {/* <li className={step >= 5 ? "filled" : ""}>4</li> */}

              </ul>
            </div>
            <h6 style={{ color: "#fff" }}>If you want to be part of Pink Club, then please choose Gems & Jewelry.</h6>


            <form onSubmit={handleSubmit}>
              <div className="step-1">
                <div className="form-group">
                  <label htmlFor="name">Full Name:</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && <p className="error-text">{errors.name}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="contact">Mobile Number:</label>
                  <input
                    type="tel"
                    required

                    name="contact"
                    className="form-control"
                    value={formData.contact}
                    maxLength={10}

                    onChange={handleInputChange}
                  />
                  {errors.contact && <p className="error-text">{errors.contact}</p>}
                </div>

              </div>

              <button type="submit" className="btn" >
                Continue
              </button>
            </form>
          </div>
        </div>
      )}

      {step === 2 && <Otp step={step} setStep={setStep} shareBarcode={shareBarcode}/>}
      {step === 3 && <Page2 step={step} setStep={setStep} />}

      {step === 4 && <Page3 step={step} setStep={setStep} />}
      {step === 5 && <Page4 step={step} setStep={setStep} encrypted_data={encrypted_data} setEncrypted_data={setEncrypted_data} />}
      {step === 7 && (
        <>
          <PaymentPage
            setDataShow={setStep}
            dataShow={step}
            encrypted_data={encrypted_data}
            setEncrypted_data={setEncrypted_data}
          />
        </>
      )}
      {step === 8 && (
        <>
          <PaymentDetail
            setDataShow={setStep}
            dataShow={step}
            encrypted_data={encrypted_data}
            setEncrypted_data={setEncrypted_data}
          />
        </>
      )}

      {step === 9 && (
        <>
          <PrintPage
            barcode={localStorage.getItem("barcode")}
            text={"Your registration is complete at jjsjaipur.com."}
          />
          {/* <div className="container mt-5">
              <div className="card bg-light p-4 text-center m-5">
                <h1 className="card-title">Thank you!</h1>
                <p className="card-text">
                  Your registration is complete at jjsjaipur.com.
                </p>
              </div>
            </div> */}
        </>
      )}
    </div>
  );
};

export default Index;
