import React, { useEffect, useState } from 'react';
import './css/style.css'; // Assuming you have a CSS file for styling
import logo from "./images/the.png"
import Clip from './images/clip.png'
import { message } from 'antd';
import axios from 'axios';
import Base64Image from './Base64Image';
import { Spinner } from 'react-bootstrap';
const Page2 = ({ step, setStep }) => {
    const [addressBackName, setAddressBackName] = useState(null)
    const [addressName, setAddressName] = useState(null)
    const [photoName, setPhotoName] = useState(null)
    const [allreadyPresent, setAllreadyPresent] = useState(null)
    const [loading, setLoading] = useState(false)
    const [city, setCity] = useState(null)
    const [address, setAddress] = useState(null)
    const [pincode, setPincode] = useState(null)
    const [showSpinner, setShowSpinner] = useState(false);
    const handlePrevStep = () => {
        setStep(step - 1);

    }

    const [formData, setFormData] = useState({
        gender: "",
        dob: "",
        email: "",
        photo: null,
        addressProof: null,
        addressProofBack: null,
    });

    const [errors, setErrors] = useState({});
    const [gender, setGender] = useState("");

    const handleGenderChange = (event) => {
        setGender(event.target.value);
        setFormData({ ...formData, gender: event.target.value });
    };
    useEffect(() => {
        const formData = new FormData();
        formData.append("barcode", localStorage.getItem("barcode"));

        axios
            .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
            .then((resData) => {
                const { gender, dob, email, id_proff, id_proff_back, photo } =
                    resData.data.visitor[0];
                console.log("hello i am here vistior", resData.data.visitor[0].dob)
                if (gender && dob && email && id_proff && id_proff_back && photo) {
                    setAllreadyPresent(resData.data.visitor[0])

                    setCity(resData?.data?.visitor[0]?.city);
                    setAddress(resData?.data?.visitor[0]?.address);
                    setPincode(resData?.data?.visitor[0]?.pincode);
                    setFormData({
                        gender: resData?.data?.visitor[0]?.gender ? resData?.data?.visitor[0]?.gender : "",
                        dob: resData?.data?.visitor[0]?.dob ? resData?.data?.visitor[0]?.dob : "",
                        email: resData?.data?.visitor[0]?.email ? resData?.data?.visitor[0]?.email : "",
                        photo: resData?.data?.visitor[0]?.photo ? resData?.data?.visitor[0]?.photo : "",
                        addressProof: resData?.data?.visitor[0]?.id_proff ? resData?.data?.visitor[0]?.id_proff : "",
                        addressProofBack: resData?.data?.visitor[0]?.id_proff_back ? resData?.data?.visitor[0]?.id_proff_back : ""
                    })
                    setGender(resData?.data?.visitor[0]?.gender || "")
                }


            })
    }, []);
    useEffect(() => {
        if (allreadyPresent) {

            console.log("address ,city,pincode", city, address, pincode)
        }
    }, [allreadyPresent])
    const handleAadharFrontChange = (e) => {
        setAddressName(e.target.files[0])
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            setFormData({ ...formData, addressProof: base64String });
        };
        reader.readAsDataURL(e.target.files[0]);
        // setAadharFront(e.target.files[0]);
    };

    const handleAadharBackChange = (e) => {
        setAddressBackName(e.target.files[0])

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            setFormData({ ...formData, addressProofBack: base64String });

        };
        reader.readAsDataURL(e.target.files[0]);
        // setAadharBack(e.target.files[0]);
    };

    const handleYourPhotoChange = (e) => {
        setPhotoName(e.target.files[0])

        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result;
            setFormData({ ...formData, photo: base64String });

        };
        reader.readAsDataURL(e.target.files[0]);
        // setPhoto(e.target.files[0]);
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        // Validate birth year
        if (!formData.dob || !/^(19[5-9][0-9]|20[0-4][0-9]|2050)$/.test(formData.dob)) {
            formErrors.dob = "Please enter a valid birth year between 1950 and 2050.";
            isValid = false;
        }

        // Validate email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email || !emailPattern.test(formData.email)) {
            formErrors.email = "Please enter a valid email address.";
            isValid = false;
        }

        // Validate photo
        if (!formData.photo) {
            formErrors.photo = "Please upload a photo.";
            isValid = false;
        }

        // Validate address proof
        if (!formData.addressProof) {
            formErrors.addressProof = "Please upload an address proof.";
            isValid = false;
        }

        // Validate address proof (back)
        if (!formData.addressProofBack) {
            formErrors.addressProofBack = "Please upload the back of the address proof.";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleFileChange = (event, fileType) => {
        setFormData({ ...formData, [fileType]: event.target.files[0] });
    };

    const handleNextStep = (event) => {
        event.preventDefault();
        setLoading(true)
        if (formData.dob.length === 4 && (parseInt(formData.dob) < 1910 || parseInt(formData.dob) > 2010)) {
            return message.error("Please Select the Between 1924 and 2024")
        }
        if (!gender) {
            setLoading(false)
            return message.error("Please Select the Gender")


        }
        console.log("hello", formData)
        const sendData = new FormData();
        sendData.append("step", 3);
        sendData.append("gender", formData.gender);
        sendData.append("dob", formData.dob);
        sendData.append("email", formData.email);
        sendData.append("photo", formData.photo);
        sendData.append("idproof", formData.addressProof);
        sendData.append("id_proof_back", formData.addressProofBack);
        sendData.append("city", city ? city : "");
        sendData.append("address", address ? address : "");
        sendData.append("pincode", pincode ? pincode : "");
        sendData.append("barcode", localStorage.getItem("barcode"));

        axios
            .post(`https://jjs.samaksh.in/api/visitor/v2/register-visitor`, sendData)
            .then((resData) => {
                console.log(resData.data, "response");
                // setDataShow(resData.data.visitor[0].step);
                setStep((step) => step + 1);
                setLoading(false)
            }).catch((err) => {
                message.error("Error:=>", err.message)

            })
    };
    useEffect(() => {
        console.log("formData.dob", formData);

        if (formData.dob && formData.dob.length === 4 && (parseInt(formData.dob) < 1910 || parseInt(formData.dob) > 2010)) {
            message.error("Please Select the Between 1910 and 2010")
        }
    }, [formData.dob])



    const handleBlur = () => {
        setShowSpinner(true);
        if (!pincode) {
            return
        }
        axios
            .post(`${process.env.REACT_APP_API_END_POINT}/get-city-from-pincode`, {
                pincode,
            })
            .then((resData) => {
                setTimeout(() => {
                    console.log("hello", resData?.data?.data)
                    setCity(resData?.data?.data);
                    setShowSpinner(false);
                }, 1000); // Set the spinner to false after 1 second
            });
    };


    useEffect(() => {
        if (pincode?.length === 6) {
            handleBlur();
        }
    }, [pincode]);
    return (
        <div className="wrapper">
            <div className="image-holder">
                <img src={logo} alt="" />
            </div>
            <div className="form-inner">
                <form onSubmit={handleNextStep}>
                    <div className="form-header">
                        <h3>Registration Online for the show</h3>
                    </div>
                    <div className="form-header">
                        <ul>
                            <li className="filled">1</li>
                            <li className="filled">2</li>
                            <li className="filled">3</li>

                            <li>4</li>
                            {/* <li>5</li> */}
                        </ul>
                    </div>
                    <h6 style={{color:"#fff"}}>If you want to be part of Pink Club, then please choose Gems & Jewelry.</h6>

                    <section
                        id="wizard-p-1"
                        role="tabpanel"
                        aria-labelledby="wizard-h-1"
                        className="body current"
                        aria-hidden="false"
                    >
                        <div className="form-row" >

                            <label >
                                Gender
                            </label>
                            <div className="custom-radio-container">
                                <input
                                    type="radio"
                                    id="custom-male"
                                    name="custom-radio"
                                    value="male"
                                    checked={gender === 'male'}
                                    onChange={handleGenderChange}
                                    
                                />
                                <label htmlFor="custom-male" className="custom-label">Male</label>

                                <input
                                    type="radio"
                                    id="custom-female"
                                    name="custom-radio"
                                    value="female"
                                    checked={gender === 'female'}
                                    onChange={handleGenderChange}
                                    
                                />

                                <label htmlFor="custom-female" className="custom-label">Female</label>
                            </div>

                        </div>

                        <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                            <label>Birth Year:</label>
                            <div className="form-holder">
                                <input
                                    name="dob"
                                    placeholder=""
                                    required
                                    type="text"
                                    className="form-control"
                                    data-language="en"
                                    data-date-format="dd - mm - yyyy"
                                    autoComplete="off"
                                    value={formData.dob}
                                    maxLength={4}
                                    onChange={(e) => {
                                        const value = e.target.value;

                                        // Check if the value is numeric and has a length of 8 (e.g., YYYYMMDD)
                                        if (/^\d*$/.test(value)) {

                                            setFormData({ ...formData, dob: value });
                                        }
                                    }}


                                />
                            </div>
                        </div>
                        <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                            <label>Your Email:</label>
                            <div className="form-holder">
                                <input name="email" type="email" className="form-control"
                                    required
                                    value={formData.email ? formData.email : allreadyPresent?.email}
                                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                />
                                {errors.email && <span className="error">{errors.email}</span>}
                            </div>
                        </div>
                        <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                            <label htmlFor="pincode">Pincode:</label>
                            <div className="form-holder">
                                <input
                                    name="pincode"
                                    required
                                    onChange={(e) => setPincode(e.target.value)}
                                    value={pincode}

                                    type="text"
                                    className="form-control"

                                    placeholder="Pincode"
                                />
                            </div>
                        </div>
                        <div className="form-row" style={{ position: 'relative', marginBottom: '3.4vh' }}>
                            <label htmlFor="reverse-city">City:</label>
                            <div className="form-holder">
                                <input
                                    type="search"
                                    id="reverse-city"
                                    name="reverse-city"
                                    placeholder="City"
                                    className="form-control"
                                    required
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                    autoComplete="off"
                                />
                                {showSpinner && (
                                    <Spinner
                                        style={{
                                            position: "absolute",
                                            right: "10px",
                                            // top: "460px",
                                            bottom: "8px",
                                            height: "20px",
                                            width: "20px",
                                            color: "#fff",
                                        }}
                                    />
                                )}
                                {/* <div id="geocoder"></div> */}
                            </div>
                        </div>
                        <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                            <label htmlFor="address">Address:</label>
                            <div className="form-holder">
                                <input
                                    name="address"
                                    type="text"
                                    required
                                    value={address}
                                    onChange={(e) => setAddress(e.target.value)}

                                    className="form-control"
                                    placeholder="Address"
                                />
                            </div>
                        </div>


                        <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                            <label>Photo:</label>
                            {/* <div className="file-input-container">
                                <label className="file-input-label d-flex align-items-center m-0">
                                    <span ><img className='clip-image' src={Clip} /></span>
                                    <input type="file" id="photo" className="file-input form-control"

                                        required={allreadyPresent ? false : true}
                                        onChange={handleYourPhotoChange}
                                    />
                                    <span className="placeholder-text">{photoName ? `${photoName?.name.slice(0, 25)}...` : `Select File`}</span>
                                </label>

                            </div> */}
                            <div className="file-input-container d-flex" style={{ width: '100%' }}>
                                <label
                                    className="file-input-label d-flex align-items-center m-0"
                                >
                                    <span>
                                        <img className='clip-image' src={Clip} alt="Clip icon" />
                                    </span>
                                    <input
                                        type="file"
                                        id="photo"
                                        className="file-input form-control"
                                        required={allreadyPresent ? false : true}

                                        onChange={handleYourPhotoChange}
                                    />
                                    <span className="placeholder-text">
                                        {photoName ? `${photoName?.name.slice(0, 25)}...` : `Select File`}
                                    </span>
                                </label>

                                {/* Show image preview when a file is uploaded */}
                                {/* {photoName && (
                                    <div className="image-preview" >
                                        <img
                                            src={URL.createObjectURL(photoName)}
                                            alt="Preview"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </div>
                                )} */}
                            </div>
                            <div className="form-holder">

                                {allreadyPresent && photoName ? (
                                    // Show the image preview if both allreadyPresent and photoName are present
                                    <div className="image-preview">
                                        <img
                                            src={URL.createObjectURL(photoName)}
                                            alt="Preview"
                                            style={{ width: '300px', height: 'auto', objectFit: 'contain' }}
                                        />
                                    </div>
                                ) : allreadyPresent ? (
                                    // Show Base64 image if only allreadyPresent is true
                                    <div className="image-preview">
                                        <Base64Image base64String={allreadyPresent?.photo} />
                                    </div>
                                ) : photoName && (
                                    // Otherwise, show the preview of the uploaded image
                                    <div className="image-preview">
                                        <img
                                            src={URL.createObjectURL(photoName)}
                                            alt="Preview"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </div>
                                )}

                                {/* <a
                                    id="aphoto_str"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://jjs.samaksh.in/uploads/photos/dz7823.jpg?1726121151783"
                                >
                                    View Existing
                                </a> */}
                            </div>
                        </div>

                        <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                            <label>Address Proof:</label>
                            <div className="form-holder">
                                <div className="file-input-container">
                                    <label className="file-input-label d-flex align-items-center m-0">
                                        <span ><img className='clip-image' src={Clip} /></span> {/* Unicode for the clip icon */}
                                        <input type="file" id="addressProof" className="file-input form-control"
                                            required={allreadyPresent ? false : true}
                                            onChange={handleAadharFrontChange}

                                        />
                                        <span className="placeholder-text">{addressName ? `${addressName?.name.slice(0, 25)}...` : `Select File`}</span> {/* Custom placeholder */}

                                    </label>
                                </div>
                                {/* {allreadyPresent &&
                                    <Base64Image base64String={allreadyPresent?.id_proff} />
                                 
                                } */}
                                {allreadyPresent && addressName ? (
                                    // Show the image preview if both allreadyPresent and photoName are present
                                    <div className="image-preview">
                                        <img
                                            src={URL.createObjectURL(addressName)}
                                            alt="Preview"
                                            style={{ width: '300px', height: 'auto', objectFit: 'contain' }}
                                        />
                                    </div>
                                ) : allreadyPresent ? (
                                    // Show Base64 image if only allreadyPresent is true
                                    <div className="image-preview">
                                        <Base64Image base64String={allreadyPresent?.id_proff} />
                                    </div>
                                ) : addressName && (
                                    // Otherwise, show the preview of the uploaded image
                                    <div className="image-preview">
                                        <img
                                            src={URL.createObjectURL(addressName)}
                                            alt="Preview"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </div>
                                )}
                                {/* <a
                                    id="aidproof_str"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://jjs.samaksh.in/uploads/idproofs/dz7823.jpg?1726121151783"
                                >
                                    View Existing
                                </a> */}
                            </div>
                        </div>
                        <div className="form-row" style={{ marginBottom: '3.4vh' }}>
                            <label>Address Proof (back):</label>
                            <div className="form-holder">
                                <div className="file-input-container">
                                    <label className="file-input-label d-flex align-items-center m-0">
                                        <span ><img className='clip-image' src={Clip} /></span> {/* Unicode for the clip icon */}
                                        <input type="file"


                                            id="addressProofBack"
                                            className="file-input form-control"
                                            required={allreadyPresent ? false : true}
                                            onChange={handleAadharBackChange}
                                        />
                                        <span className="placeholder-text">{addressBackName ? `${addressBackName?.name.slice(0, 25)}...` : `Select File`}</span> {/* Custom placeholder */}

                                    </label>
                                </div>
                                {allreadyPresent && addressBackName ? (
                                    // Show the image preview if both allreadyPresent and photoName are present
                                    <div className="image-preview">
                                        <img
                                            src={URL.createObjectURL(addressBackName)}
                                            alt="Preview"
                                            style={{ width: '300px', height: 'auto', objectFit: 'contain' }}
                                        />
                                    </div>
                                ) : allreadyPresent ? (
                                    // Show Base64 image if only allreadyPresent is true
                                    <div className="image-preview">
                                        <Base64Image base64String={allreadyPresent?.id_proff_back} />
                                    </div>
                                ) : addressBackName && (
                                    // Otherwise, show the preview of the uploaded image
                                    <div className="image-preview">
                                        <img
                                            src={URL.createObjectURL(addressBackName)}
                                            alt="Preview"
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </div>
                                )}
                                {/* {allreadyPresent &&
                                    <Base64Image base64String={allreadyPresent?.id_proff_back} />
                              
                                } */}
                            </div>
                        </div>
                    </section>
                    <div className="btn-box">
                        <button type='submit' className="btn" >{loading ? <div class="spinner-border text-danger" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div> : 'Continue'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Page2;
